import * as React from 'react'
import { Link } from 'gatsby'

const SublinkContainer = () => {
    return (
        <div className='sublink-container'>
            <p className='button-link'>
                <Link to="/whatIsShamisen/shamisenIntroduction/">Introduction</Link>
            </p>
            <p className='button-link'>
                <Link to="/whatIsShamisen/shamisenTypesAndStyles/">Types and Styles</Link>
            </p>
            <p className='button-link'>
                <Link to="/whatIsShamisen/shamisenNaguata/">Naguata</Link>
            </p>
        </div>
    )
}

export default SublinkContainer