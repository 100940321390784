import * as React from 'react'
import Layout from '../../components/layout'
import SublinkContainer from '../../components/wisSublink'
import { StaticImage } from 'gatsby-plugin-image'
import '../../css/wis.css'

const WhatIsShamisenTypesAndStyles = () => {
    return (
        <Layout pageTitle='Types and styles' subTitle='Shamisen Types and Styles' description="An introduction to the Japanese shamisen. Learn about the history, styles of instrument and the different genres of music.">
            <div className='wis-types-and-styles-page sub-wrapper'>
                <SublinkContainer />
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Shamisen Types and Styles
                    </h2>
                    <div className='section-content'>
                        <p>
                            The shamisen <span lang='ja'>三味線</span> (also known as sangen or samisen) is a three-stringed traditional Japanese instrument derived from the Chinese sanxian. There are several different types of shamisen each designed to suit a particular style, from accompanying kabuki theatre to puppet plays to traditional folk songs.
                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-img'
                                alt='Three different types of shamisen'
                                src='../../images/what-is-shamisen/three-shamisen.webp'
                            />
                        </div>
                        <p>
                            There are three main sizes of shamisen, designated according to the thickness of the neck (hosozao, or thin neck; chuuzao, or medium neck and futozao, or thick neck), and many different performance styles. Each style is commonly associated with a particular neck size, but the styles don't necessarily require specific sizes. For example, nagauta style players usually use hosozao size (thin neck) shamisen, but they have also been made with surprisingly thick necks. Shamisen for tsugaru style are generally futozao size (thick neck), but the instruments are also sometimes made as hosozao (thin neck) to accommodate smaller-sized players.
                        </p>
                    </div>
                </section>
                <section className='normal-section'>
                    <h3>
                        Hosozao (Thin Neck Size)
                    </h3>
                    <div className='section-content'>
                        <p>
                            Hosozao shamisen are generally used for nagauta, kouta and hauta (old popular song). As the chief instrument used in Kabuki, it is played in a wide range of modes to suit the action on stage, from very slow, regular, lyrical lines to rapid and dramatic accenting techniques. Nagauta bachi are traditionally made from ivory, but are more commonly wood or hard plastic.
                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-img'
                                alt='A hosozao or think necked shamisen resting on a pillow.'
                                src='../../images/what-is-shamisen/hosozao-shamisen.webp'
                            />
                        </div>
                    </div>
                </section>
                <section className='normal-section'>
                    <h3>
                        Futozao (Thick Neck Size)
                    </h3>
                    <div className='section-content'>
                        <p>
                            Futozao shamisen are commonly used for tsugaru (a more modern, improvisational playing style) and gidayu (a dramatic accompaniment evolved from the bunraku puppet-theatre.)
                        </p>
                    </div>
                </section>
                <section className='normal-section'>
                    <h3>
                        Tsugaru
                    </h3>
                    <div className='section-content'>
                        <p>
                            The tsugaru style is said to have originated on the streets by the bousama (blind musicians), who developed this livelier style to attract audiences. In recent years, thanks to the many talented musicians who perform the tsugaru style (Yoshida Brothers, Wagakki Band) and to movies such as Kubo and the Three Strings, the style has been brought to popularity on the world stage. The tsugaru bachi (plectrum) is comparatively smaller, and the instrument's koma (bridge) has a relatively short height of 8mm. Both of these features facilitate the aggressive mode of playing which characterizes the tsugaru style.
                        </p>
                    </div>
                </section>
                <section className='normal-section'>
                    <h3>
                        Gidayu Style
                    </h3>
                    <div className='section-content'>
                        <p>
                            Unlike the solo-centric and showy tsugaru style, the gidayu style was developed to accompany dramatic performances. This style uses a bachi with 7mm thick tips, unlike the thin 1~2mm bachi tips used for tsugaru shamisen, and the instrument's koma is around 19mm tall. The extreme sizes/thicknesses of the accessories lend to the gidayu shamisen's unique, biwa-like tone.

                        </p>
                        <p>
                            Source: <a href="https://bachido.com/">Bachido, a community of shamisen players from around the world.</a> This is a great place to meet like minded enthusiasts and learn more about a variety of topics related to shamisen.
                        </p>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default WhatIsShamisenTypesAndStyles